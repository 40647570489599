import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import { Manrope } from 'next/font/google'
import LocalFont from 'next/font/local'
import { ToastContainer } from 'react-toastify'
import { useMedia } from 'react-use'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'
import { registerServiceWorker } from '../utilities/registerServiceWorker'

const manrope = Manrope({
	weight: ['600', '700'],
	subsets: ['latin', 'latin-ext'],
	display: 'swap',
})

const gtMaru = LocalFont({
	src: [
		{
			path: '../styles/fonts/gt-maru-bold.woff2',
			style: 'normal',
			weight: '700',
		},
		{
			path: '../styles/fonts/gt-maru-bold-oblique.woff2',
			style: 'italic',
			weight: '700',
		},
	],
})

registerServiceWorker()

function MyApp({ Component, pageProps }: AppProps) {
	const theme = useMedia('(prefers-color-scheme: dark)', false) ? 'dark' : 'light'

	return (
		<>
			<style jsx global>{`
				:root {
					--font-manrope: ${manrope.style.fontFamily};
					--font-gt-maru: ${gtMaru.style.fontFamily};
					--font-body: var(--font-manrope);
					--font-heading: var(--font-gt-maru);
				}
			`}</style>
			<SharedLoadingIndicatorContextProvider>
				<PageNavigationLoadingTracker />
				<SharedProgressLoadingIndicator />
				<ToastContainer theme={theme} />
				<Component {...pageProps} />
				<ReactQueryDevtools />
			</SharedLoadingIndicatorContextProvider>
		</>
	)
}

export default api.withTRPC(MyApp)
